import React, { useState } from "react";
import { useQuery } from "react-query";
import { getProductOrderDetails } from "../../../service";
import { formatedOrderDetails } from "../../organisms/orders-list/OrdersList.utils";
import { Col, Flex, Row, Spin, Tag } from "antd";
import { EnvironmentOutlined, SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const OrderDetailsModal: React.FC<any> = ({ id }) => {
  const [details, setDetails] = useState<any>();

  const { isLoading } = useQuery<any, Error>(
    [`/product-order-details`, id],
    () => getProductOrderDetails({ id: id }),
    {
      onSuccess(data) {
        setDetails(formatedOrderDetails(data));
      },
      enabled: !!id,
    }
  );

  if (!details && isLoading)
    return (
      <Flex
        style={{
          width: "100%",
          height: "200px",
        }}
        justify="center"
        align="center"
      >
        <Spin size="large" />
      </Flex>
    );

  if (details)
    return (
      <>
        <Flex
          id="HEADER"
          style={{
            // background: "#fafafa",
            borderRadius: "12px 12px 0 0",
            // padding: "10px",
            height: "40px",
          }}
          justify="space-between"
          align="center"
        >
          <div>
            {dayjs(details.header.createdAt).format("DD-MM-YYYY HH:mm")}
          </div>
          <div>
            <Tag
              color={
                details.header.paymentsType === "OFFLINE" ? "gold" : "green"
              }
            >{`${details.header.paymentsLabel} ${(
              details.header.price / 100
            ).toFixed(2)} zł`}</Tag>
          </div>
        </Flex>
        <hr
          style={{
            marginBottom: "0px",
            // marginTop: "20px",
            width: "100%",
            background: "#fafafa",
            color: "#fafafa",
          }}
        />
        <Row>
          <Col
            span={24}
            style={{
              //   textAlign: "center",
              fontWeight: 600,
              //   padding: 10,
              fontSize: 16,
            }}
          >
            ID: {details.id}
          </Col>
        </Row>
        <Row>
          <Col
            span={12}
            style={{
              paddingTop: "15px",
            }}
          >
            {details.cart.map((row: any) => {
              return (
                <div
                  style={{
                    display: row.texts.length > 1 ? "flex" : "block",
                    // paddingLeft: "10px",
                    // paddingRight: "10px",
                    justifyContent:
                      row.texts.length > 1 ? "space-between" : "start",
                    ...row.style,
                  }}
                >
                  {row.texts.map((text: any) => {
                    return <span style={{ ...text.style }}>{text.text}</span>;
                  })}
                </div>
              );
            })}
          </Col>
          <Col
            span={12}
            style={{
              paddingTop: "15px",
            }}
          >
            {details.address.map((row: any) => {
              return (
                <div
                  style={{
                    display: row.texts.length > 1 ? "flex" : "block",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    justifyContent:
                      row.texts.length > 1 ? "space-between" : "start",
                    ...row.style,
                  }}
                >
                  {row.texts.map((text: any) => {
                    return <span style={{ ...text.style }}>{text.text}</span>;
                  })}
                </div>
              );
            })}
          </Col>
        </Row>
        <hr
          style={{
            marginBottom: "0px",
            marginTop: "20px",
            width: "100%",
            background: "#fafafa",
            color: "#fafafa",
          }}
        />
        <div style={{}}>
          {details?.labels?.map((element: any) => (
            <Tag
              style={{
                marginBottom: "5px",
              }}
              color={element.color}
            >
              {element.text}
            </Tag>
          ))}
        </div>
        <Flex
          id="FOOTER"
          style={{
            borderRadius: "0 0 12px 12px",
            // paddingLeft: "10px",
            // paddingRight: "10px",
            paddingBottom: "10px",
            minHeight: "40px",
          }}
          justify="space-between"
          align="center"
        >
          <div>
            <p style={{ marginBottom: 5, fontWeight: 500 }}>
              {details.footer.deliveryTimeType === "NOW"
                ? "Najszybciej jak to mozliwe"
                : `Z datą: ${details.footer.deliveryOrderDate}`}
            </p>
            {details.footer.deliveryType === "TO_THE_ADDRESS" && (
              <>
                <a
                  href={`https://www.google.com/maps/place/?q=place_id:${details.footer.deliveryPlaceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  //   onClick={(e)=>e.stopPropagation()}
                >
                  <p style={{ margin: 0, fontWeight: 300 }}>
                    {details.footer.deliveryLabel}
                  </p>
                </a>
                {details?.footer?.apertmenNumber && (
                  <p style={{ margin: 0, fontWeight: 300 }}>
                    {`Numer mieszkania: ${details.footer.apertmenNumber}`}
                  </p>
                )}
                {details?.footer?.deliveryZone && (
                  <p style={{ margin: 0, fontWeight: 300 }}>
                    {`Nazwa strefy: ${details.footer.deliveryZone}`}
                  </p>
                )}

                {details?.footer?.deliveryNote && (
                  <p style={{ margin: 0, fontWeight: 300 }}>
                    {`Komentarz: ${details.footer.deliveryNote}`}
                  </p>
                )}
              </>
            )}
          </div>
          <div>
            {details.footer.deliveryType === "TO_THE_ADDRESS" && (
              <Tag
                style={{
                  margin: 0,
                }}
                icon={<SendOutlined />}
              >
                Dostawa
              </Tag>
            )}
            {details.footer.deliveryType === "SELF_PICKUP" && (
              <Tag icon={<EnvironmentOutlined />}>Odbiór osobisty</Tag>
            )}
          </div>
        </Flex>
      </>
    );

  return null;
};
// https://www.google.com/maps/place/?q=place_id:ChIJV4howMJ7PUcR0ngpn3dZFuo
// https://www.googldetails.com/maps/place/?q=place_id:ChIJV4howMJ7PUcR0ngpn3dZFuo
