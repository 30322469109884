const createCartList = (cart: any) => {
  return cart
    .map((element: any, index: number) => {
      const product = {
        style: { marginBottom: "5px", marginTop: "8px" },
        texts: [
          {
            style: {
              fontWeight: 500,
            },
            text: `${index + 1}. (${element.cart_positoin.meal_quantity}x) ${
              element.cart_positoin.meal_name
            }`,
          },
        ],
      };
      const addidton = element.cart_positoin.meal_options
        .map((e: any) => {
          return e.controls.map((el: any) => {
            return {
              style: { marginBottom: "" },
              texts: [
                {
                  style: {
                    fontWeight: 300,
                    paddingLeft: "10px",
                  },
                  text: `${el.name}: ${el.value}`,
                },
              ],
            };
          });
        })
        .flat();

      const comment = element?.cart_positoin?.description
        ? {
            style: { marginBottom: "0px", marginTop: "2px" },
            texts: [
              {
                style: {
                  fontWeight: 300,
                },
                text: `Komentarz: ${element.cart_positoin.description}`,
              },
            ],
          }
        : {
            style: {},
            texts: [
              {
                style: {},
                text: ``,
              },
            ],
          };
      return [product, ...addidton, comment];
    })
    .flat();
};
const createAddressList = (address: any, additionalSections: any) => {
  const addressFE = [
    {
      style: { marginBottom: "3px", marginTop: "8px" },
      texts: [
        {
          style: {
            fontWeight: 500,
          },
          text: 'Dane kontaktowe',
        },
      ],
    },
    {
      style: {},
      texts: [
        {
          style: {
            fontWeight: 400,
          },
          text: `Imie i nazwisko: ${address.clientName}`,
        },
      ],
    },
    {
      style: {},
      texts: [
        {
          style: {
            fontWeight: 400,
          },
          text: `Email: ${address.email}`,
        },
      ],
    },
    {
      style: {},
      texts: [
        {
          style: {
            fontWeight: 400,
          },
          text: `Numer telefonu: ${address.telNumber}`,
        },
      ],
    },
  ];
  const additionalSectionsFE = additionalSections
    .map((element: any) => {
      const section = {
        style: { marginBottom: "3px", marginTop: "8px" },
        texts: [
          {
            style: {
              fontWeight: 500,
            },
            text: element.sectionName,
          },
        ],
      };
      const controls = element.controls.map((e: any) => {
        return {
          style: { marginBottom: "" },
          texts: [
            {
              style: {
                fontWeight: 400,
              },
              text: `${e.name}: ${e.value}`,
            },
          ],
        };
      });

      return [section, ...controls];
    })
    .flat();

  return [...addressFE, ...additionalSectionsFE];
};

export const formatedOrderDetails = (element: any) => {
  return {
    id: element.id,
    status: element.status,
    createdAt: element.createdAt,
    updatedAt: element.updatedAt,
    header: {
      createdAt: element.createdAt,
      price: element.price,
      paymentsType: element.details.paymentsType,
      paymentsLabel: element.details.payments,
    },
    cart: [...createCartList(element.details.cart)],
    address: [
      ...createAddressList(element, element.details.additionalSections),
    ],
    footer: {
      deliveryTimeType: element.details.deliveryTimeType,
      deliveryOrderDate: element.details.orderDate,
      deliveryType: element.details.delivery_type,
      deliveryLabel: element.details.deliveryLabel,
      deliveryPlaceId: element.details.deliveryPlaceId,
      deliveryPrice: element.details.deliveryPrice,
      deliveryZone: element.details.deliveryZone,
      apertmenNumber: element.details.apertmenNumber,
      deliveryNote: element.details.deliveryNote,
    },
  };
};

export const createOrdersList = (ordersList: any) => {
  if (Array.isArray(ordersList)) {
    return ordersList.map((element) => {
      return formatedOrderDetails(element);
    }) as [];
  }
  return [];
};
