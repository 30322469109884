import { Content as AntContent } from "antd/es/layout/layout";
import React, { useState } from "react";

import styled from "styled-components";
import { OrdersHeader } from "../organisms/orders-header/OrdersHeader";
import { OrdersList } from "../organisms/orders-list/OrdersList";
import { Col, Row } from "antd";

export const Content = styled(AntContent)`
  padding: 8px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  margin: 0;
`;

export const Orders: React.FC = () => {
  const [height, setHeight] = useState(0);
  const [showDone, setShowDone] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);


  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        maxHeight: "100%",
        minHeight: "100%",
      }}
    >
      <OrdersHeader
        setHeight={setHeight}
        setShowDone={setShowDone}
        showDone={showDone}
        setSelectedBranch={setSelectedBranch}
        selectedBranch={selectedBranch}
      />
      <div
        style={{
          flex: "0 1 auto",
        }}
      >
        <Row
          gutter={12}
          style={{
            padding: "12px",
          }}
        >
          <Col span={showDone ? 6 : 8}>
            <OrdersList
              height={height}
              status="IN_QUEUE"
              selectedBranch={selectedBranch}
            />
          </Col>
          <Col span={showDone ? 6 : 8}>
            <OrdersList
              height={height}
              status="IN_PROGRESS"
              selectedBranch={selectedBranch}
            />
          </Col>
          <Col span={showDone ? 6 : 8}>
            <OrdersList
              height={height}
              status="IN_DELIVERY"
              selectedBranch={selectedBranch}
            />
          </Col>
          {showDone && (
            <Col span={6}>
              <OrdersList
                height={height}
                status="DONE"
                selectedBranch={selectedBranch}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};
