import React, { useState } from "react";
import { Content as AntContent } from "antd/es/layout/layout";
import {
  GoogleMap,
  PolygonF,
  DrawingManager,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getBranchInfo, setDeliveryZones } from "../../../service";
import { useParams } from "react-router-dom";
import { Button, Flex, Form, Input, Modal, Result } from "antd";

export const Content = styled(AntContent)`
  padding: 8px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  margin: 0;
`;
const libraries: ("places" | "geometry" | "drawing")[] = [
  "geometry",
  "places",
  "drawing",
];

export const DeliveryMap: React.FC<any> = ({ zones }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA3lh6-T9qUYkdo46jFI1jaNQ_z2JNdCEs",
    libraries,
  });

  const [form] = Form.useForm();
  const { id } = useParams();
  const [startPoint, setStartPoint] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paths, setPaths] = useState<any>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const query = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(setDeliveryZones, {
    onSuccess: async (data) => {
      setPaths(null);
      setIsModalOpen(false);
      await query.invalidateQueries(["/delivery-zones"]);
      await query.refetchQueries(["/delivery-zones"]);
    },
  });

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const price = parseFloat(values.price) * 100;
        const freeAbove = parseFloat(values.freeAbove) * 100;
        if (paths && id && price && freeAbove) {
          const zones = paths
            .getPath()
            .getArray()
            .map((point: any, index: number) => ({
              index: index,
              lat: point.lat(),
              lng: point.lng(),
            }));

          mutateAsync({
            data: {
              ...values,
              price,
              freeAbove,
              companyBranchId: parseInt(id),
              zones: zones,
            },
          });
          form.resetFields();
        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed: ", errorInfo);
      });
  };

  const handleCancel = () => {
    paths.setMap(null);
    setPaths(null);
    setIsModalOpen(false);
    form.resetFields();
  };

  const [configError, setConfigError] = useState<any>(false);

  const { isLoading: isLoadingBranchInformation } = useQuery<any, Error>(
    ["/branch-information", id],
    () => getBranchInfo({ id }),
    {
      onSuccess(data) {
        setConfigError(false);
        if (data?.adress) {
          setStartPoint({
            lat: data.adress.lat,
            lng: data.adress.lng,
          });
        } else {
          setConfigError(true);
        }
      },
      enabled: !!id,
    }
  );

  const mapContainerStyle = {
    width: "100%",
    height: "calc(100% - 45px)",
  };

  const allDeliveryAreas =
    zones?.map((e: any) =>
      e?.zones?.sort((a: any, b: any) => {
        return a?.index - b?.index;
      })
    ) || [];

  const [draw, setDraw] = useState<any>(false);
  const [address, setAddress] = useState("");
  const [isAddressInsideArea, setIsAddressInsideArea] = useState<any>("");
  const [map, setMap] = useState<any>(null);

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // console.log('startPoint',startPoint)
      // const bounds = new window.google.maps.LatLngBounds(startPoint);
      // map.setZoom(14);
      // map.fitBounds(bounds);

      setMap(map);
    },
    [startPoint]
  );

  const handlePolygonComplete = (polygon: google.maps.Polygon) => {
    setPaths(polygon);
    showModal();
  };

  const checkAddressInArea = () => {
    if (!map) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        const addressLatLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };

        let isInside = false;
        for (const area of allDeliveryAreas) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              new window.google.maps.LatLng(addressLatLng),
              new window.google.maps.Polygon({ paths: area })
            )
          ) {
            isInside = true;
            break;
          }
        }

        setIsAddressInsideArea(isInside as any);
      } else {
        setIsAddressInsideArea(null);
      }
    });
  };

  if (isLoadingBranchInformation) return null;
  if (configError)
    return (
      <Flex
        justify="center"
        align="center"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Result
          status="warning"
          title="Skontaktuj sie z administratorem aby skonfugurować połozenie geograficzne
      oddziału"
        />
      </Flex>
    );

  if (isLoaded && !isLoadingBranchInformation && !configError)
    return (
      <>
        <Content>
          <Flex
            justify="start"
            gap={8}
            align="center"
            style={{
              marginBottom: 10,
            }}
          >
            <Input
              type="text"
              style={{
                maxWidth: 300,
              }}
              placeholder="Wprowadz adres testowy"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Button onClick={checkAddressInArea}>Sprawdz adres</Button>
            <Button onClick={() => setDraw(!draw)}>
              {draw ? "Zakończ rysowanie" : "Rysuj strefę"}
            </Button>

            {isAddressInsideArea === true && (
              <p>The address is inside a delivery area.</p>
            )}
            {isAddressInsideArea === false && (
              <p>The address is outside all delivery areas.</p>
            )}
            {isAddressInsideArea === null && <p>Invalid address.</p>}
          </Flex>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={startPoint}
            zoom={11}
            onLoad={onLoad}
          >
            {allDeliveryAreas.length > 0 &&
              allDeliveryAreas.map((coords: any, index: any) => (
                <PolygonF
                  key={index}
                  path={coords}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.1,
                    strokeWeight: 1,
                    fillColor: "#FF0000",
                    fillOpacity: 0.2,
                  }}
                />
              ))}
            {draw && (
              <DrawingManager
                onPolygonComplete={handlePolygonComplete}
                options={{
                  drawingControl: false,
                  drawingMode: "polygon" as any,
                }}
              />
            )}
            {startPoint && <Marker position={startPoint} />}
          </GoogleMap>
        </Content>
        <Modal
          title="Dodaj strefę dostaw"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form layout="vertical" form={form}>
            <Form.Item
              label={"Nazwa strefy"}
              name="name"
              rules={[{ required: true, message: "Pole wymagane" }]}
            >
              <Input
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                pattern="^\d+(\.\d{1,2})?$"
              />
            </Form.Item>
            <Form.Item
              label={"Cena dostawy w strefie (zł)"}
              name="price"
              rules={[{ required: true, message: "Pole wymagane" }]}
            >
              <Input
                type="number"
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                pattern="^\d+(\.\d{1,2})?$"
              />
            </Form.Item>
            <Form.Item
              label={"Dostawa darmowa od zamówienia o wartości (zł)"}
              name="freeAbove"
              rules={[{ required: true, message: "Pole wymagane" }]}
            >
              <Input
                type="number"
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                pattern="^\d+(\.\d{1,2})?$"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  return null;
};
