import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  HolderOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { Table, Flex, Select, Badge, TableColumnsType, Switch } from "antd";
import styled from "styled-components";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

export const CostumTable = styled(Table)`
  && .ant-table-row {
    background: #fafafa;
  }
  && .ant-table-cell {
    padding: 0px 0px 0px 4px !important;
  }
`;

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    width: "20px",
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface ExpandedDataType {
  id: string;
  key: React.Key;
  price: string;
  name: string;
}

const MealOptionsList: React.FC = () => {
  const columns: TableColumnsType<ExpandedDataType> = [
    {
      key: "sort",
      width: "0.1%",
    },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => (
        <p
          style={{
            textAlign: "end",
            paddingRight: "10px",
          }}
        >
          {price}
        </p>
      ),
    },
    {
      title: "Switch",
      dataIndex: "switch",
      render: (_, {}) => (
        <Switch
          defaultChecked
          style={{
            marginRight: "7px",
          }}
        />
      ),
      width: "0.1%",
    },
    {
      title: "More",
      dataIndex: "id",
      render: (_, { id }) => <MoreOutlined key={id} />,
      width: "0.1%",
    },
  ];

  const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      id: i.toString(),
      key: i.toString(),
      price: "2,00 zł",
      name: "This is production name",
    });
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <CostumTable
          style={{
            paddingLeft: "30px",
          }}
          showHeader={false}
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
          columns={columns as any}
          dataSource={data}
          pagination={false}
        />
      </SortableContext>
    </DndContext>
  );
};

export default MealOptionsList;
