import {
  EditOutlined,
  HolderOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  Card,
  Button,
  Image,
  Switch,
  Form,
  FormInstance,
  Input,
  InputRef,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Flex,
} from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import MealOptionsList from "./components/MealOptionsList";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  createServices,
  getServiceCategory,
  getServices,
  getServicesCategories,
} from "../../../service";

export const CostumCard = styled(Card)`
  width: 100% !important;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  z-index: 1;
  && .ant-card-body,
  .ant-card-head {
    padding: 8px !important;
  }
  && .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-cell {
    padding: 8px 8px 8px 4px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
  image: string;
  variants: number;
  status: string;
  id: number;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: "0.1%",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (_, { name, id }) => (
      <Link
        style={{
          textDecoration: "none",
          color: "black",
        }}
        to={`/service/${id}`}
      >
        <div>{name}</div>{" "}
      </Link>
    ),
    width: "85%",
  },
  {
    title: "Switch",
    dataIndex: "switch",
    render: (_, {}) => (
      <Switch defaultChecked onClick={(_e, event) => event.stopPropagation()} />
    ),
    width: "0.1%",
  },
  {
    title: "More",
    dataIndex: "id",
    render: (_, { id }) => <MoreOutlined key={id} />,
    width: "0.1%",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const ServiceList: React.FC = () => {
  const [form] = Form.useForm();
  const { categoryID } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dataSource, setDataSource] = useState<any>([]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.key === active.id);
        const overIndex = previous.findIndex((i: any) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useQuery<any, Error>(
    ["/service-category", categoryID],
    () => categoryID && getServiceCategory({ id: categoryID }),
    {
      enabled: !!categoryID,
      onSuccess(data) {
        setCategoryName(data.name);
      },
    }
  );

  const { refetch, isLoading } = useQuery<any, Error>(
    ["/services", categoryID],
    () => categoryID && getServices({ id: categoryID }),
    {
      enabled: !!categoryID,
      onSuccess(data) {
        const tableData = data.map((e: any) => {
          return {
            ...e,
          };
        });
        setDataSource(tableData);
      },
    }
  );

  const { mutateAsync, isLoading: createIsLoading } = useMutation(
    createServices,
    {
      onSuccess: () => {
        form.resetFields();
        setIsModalOpen(false);
        refetch();
      },
      onError: () => {
        notification.error({
          message: "Błąd logowania",
          style: {
            background: "#ff4d4f30",
          },
        });
      },
    }
  );

  if (categoryID)
    return (
      <>
        <CostumCard
          title={categoryName}
          extra={
            <Flex gap={8}>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              />

              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Dodaj danie
              </Button>
            </Flex>
          }
          style={{ width: 300 }}
        >
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={dataSource.map((i: any) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description="Custom message">
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Dodaj usługę
                    </Button>
                  </Empty>
                )}
              >
                <Table
                  loading={isLoading || createIsLoading}
                  showHeader={false}
                  components={{
                    body: {
                      row: Row,
                      cell: EditableCell,
                    },
                  }}
                  rowKey="key"
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  style={{
                    zIndex: 1,
                  }}
                />
              </ConfigProvider>
            </SortableContext>
          </DndContext>
        </CostumCard>
        <Modal
          title="Dodawanie usługi w kategorii "
          open={isModalOpen}
          onOk={() => {
            console.log({
              ...form.getFieldsValue(),
              serviceCategoryId: parseInt(categoryID),
            });

            mutateAsync({
              ...form.getFieldsValue(),
              serviceCategoryId: parseInt(categoryID),
            });
          }}
          onCancel={() => {
            form.resetFields();
            setIsModalOpen(false);
          }}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Field A" name="name" rules={[{ required: true }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );

  return null;
};

export default ServiceList;
