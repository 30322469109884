import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Flex,
  Form,
  Input,
  Layout,
  Radio,
  Select,
  Space,
  Spin,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getCompanyBranches } from "../../../service";

export const OrdersHeader: React.FC<{
  setHeight: any;
  setShowDone?: any;
  showDone?: any;
  setSelectedBranch?: any;
  selectedBranch?: any;
}> = ({
  setHeight,
  setShowDone,
  showDone,
  setSelectedBranch,
  selectedBranch,
}) => {
  const [form] = Form.useForm();
  const [showFilters, setShowFilters] = useState(false);
  const divRef = useRef(null);

  const [options, setOptions] = useState([]);

  const { isLoading } = useQuery<any, Error>(
    ["/company-branch"],
    () => getCompanyBranches(),
    {
      onSuccess(data) {
        const sellectOptions = data.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        });

        const branchId = data.find(
          (e: any) => e.id?.toString() === selectedBranch?.toString()
        );

        if (branchId) {
          setSelectedBranch(branchId?.id ?? null);
        } else {
          setSelectedBranch(data?.[0]?.id ?? null);
        }

        setOptions(sellectOptions);
      },
      enabled: !!setSelectedBranch,
    }
  );

  useEffect(() => {
    if (divRef.current) {
      setHeight((divRef.current as any)?.offsetHeight);
    }
  }, [showFilters]);

  const onChange: CheckboxProps["onChange"] = (e) => {
    setShowDone(e.target.checked);
  };
  if (isLoading) return <Spin spinning={true} fullscreen />;
  return (
    <div
      ref={divRef}
      style={{
        minHeight: "60px",
        paddingLeft: "4px",
        paddingRight: "4px",
        background: "#fff",
        flex: "0 1 auto",
      }}
    >
      <Flex
        justify="space-between"
        align="center"
        style={{
          minHeight: "60px",
        }}
      >
        <Flex justify="space-between" align="center" gap={20}>
          <h2
            style={{
              margin: 0,
            }}
          >
            Zamówienia (48h)
          </h2>

          {!!setSelectedBranch && (
            <Select
              loading={isLoading}
              style={{ minWidth: 180 }}
              onChange={(id) => {
                setSelectedBranch(id);
              }}
              value={selectedBranch}
              options={options}
            />
          )}

          {setShowDone && (
            <Checkbox onChange={onChange} checked={showDone}>
              Pokaz zakończone
            </Checkbox>
          )}
        </Flex>
        <div>
          <Button
            disabled
            icon={<FilterOutlined />}
            onClick={() => {
              setShowFilters((current) => !current);
            }}
          />
        </div>
      </Flex>
      {showFilters && (
        <Flex justify="start" align="center">
          <Form
            layout={"vertical"}
            form={form}
            style={{
              display: "flex",
              gap: 8,
            }}
          >
            <Form.Item label="Dostawa">
              <Select
                defaultValue="lucy"
                style={{ minWidth: 180 }}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>
            <Form.Item label="Płatność">
              <Select
                defaultValue="lucy"
                style={{ minWidth: 180 }}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>
          </Form>
        </Flex>
      )}
    </div>
  );
};
