import React from "react";
import { Col, Row as AntdRow, Breadcrumb } from "antd";
import CategoryList from "../molecules/category-list/CategoryList";
import AdditionsList from "../molecules/additions-list/AdditionsList";
import MealsList from "../molecules/meals-list/MealsList";

const Menu: React.FC = () => {
  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <Breadcrumb
        style={{
          marginBottom: "16px",
        }}
      >
        <Breadcrumb.Item>Menu</Breadcrumb.Item>
      </Breadcrumb>
      <AntdRow gutter={[16, 8]}>
        <Col span={6}>
          <CategoryList />
          <AdditionsList />
        </Col>
        <Col span={18}>
          <MealsList />
        </Col>
      </AntdRow>
    </div>
  );
};

export default Menu;
