import { Empty, Flex, Modal, Spin, Tag } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getProductOrders, updateProductOrders } from "../../../service";
import { createOrdersList } from "./OrdersList.utils";
import dayjs from "dayjs";
import { EnvironmentOutlined, SendOutlined } from "@ant-design/icons";
import { OrderDetailsModal } from "../../molecules/order-details-modal/OrderDetailsModal";
import { styled } from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const OrdersList: React.FC<any> = ({
  height,
  status,
  selectedBranch,
}) => {
  const [orders, setOrders] = useState([]);

  useQuery<any, Error>(
    [`/product-orders`, selectedBranch, status],
    () => getProductOrders({ id: selectedBranch, status: status }),
    {
      onSuccess(data) {
        setOrders(createOrdersList(data));
      },
      refetchIntervalInBackground: true,
      refetchInterval: status === "IN_QUEUE" ? 15000 : false,
      enabled: !!selectedBranch
    }
  );

  const query = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateProductOrders, {
    onSuccess: () => {
      query.invalidateQueries([`/product-orders`]);
    },
    onError: () => {},
  });

  const [dragOn, setDragOn] = useState(false);

  const handleDragStart = (event: any, element: any) => {
    event.dataTransfer.setData("application/json", JSON.stringify(element));
  };

  const handleDrop = async (event: any) => {
    const droppedData = JSON.parse(
      event.dataTransfer.getData("application/json")
    );
    if (droppedData.status !== status) {
      await mutateAsync({
        id: droppedData.id,
        body: {
          status: status,
        },
      });
      setDragOn(false);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault(); // To pozwala na wykonanie akcji "drop"
    const droppedData = JSON.parse(
      event.dataTransfer.getData("application/json")
    );

    if (droppedData.status !== status) setDragOn(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<any>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setSelectedOrderId(null);
    setIsModalOpen(false);
  };

  const handleCancel = async (e: any) => {
    if (e?.currentTarget?.type === "button")
      await mutateAsync({
        id: selectedOrderId,
        body: {
          status: "DONE",
        },
      });
    setDragOn(false);
    setSelectedOrderId(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        onDrop={handleDrop}
        onDragLeave={() => setDragOn(false)}
        onDragOver={handleDragOver}
        style={{
          height: `calc(100vh - ${height + 25}px)`,
          minHeight: `calc(100vh - ${height + 25}px)`,
          maxHeight: `calc(100vh - ${height + 25}px)`,
          background: "white",
          borderRadius: "12px 12px 0 0",
          // padding: "12px",
          paddingBottom: "40px",
          overflow: dragOn ? "hidden" : "auto",
        }}
      >
        <Flex
          justify="start"
          align="center"
          style={{
            height: "40px",
            background: "#fafafa",
            borderRadius: "12px 12px 0 0",
            padding: "12px",
            fontWeight: 600,
            borderBottom: "1px solid #f1f1f1",
            position: "sticky",
            top: 0,
          }}
        >
          {status === "IN_QUEUE" && "Kolejka"}
          {status === "IN_PROGRESS" && "W przygotowaniu"}
          {status === "IN_DELIVERY" && "Dostawa"}
          {status === "DONE" && "Zakończone"}
        </Flex>
        {dragOn && (
          <div
            style={{
              width: "calc(100% - 12px)",
              height: "calc(100% - 40px)",
              background: "rgba(0, 0, 0, 0.1)",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <Empty description={"Przenieś"} />
          </div>
        )}
        <div
          style={{
            padding: "12px",
          }}
        >
          {orders
            .sort((a: any, b: any) => {
              if (status === "IN_QUEUE") {
                return (
                  dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf()
                );
              } else if (status === "DONE") {
                return (
                  dayjs(b.updatedAt).valueOf() - dayjs(a.updatedAt).valueOf()
                );
              } else {
                return (
                  dayjs(a.updatedAt).valueOf() - dayjs(b.updatedAt).valueOf()
                );
              }
            })
            .map((e: any) => {
              return (
                <div
                  draggable
                  onDragStart={(event) => handleDragStart(event, e)}
                  onClick={() => {
                    setSelectedOrderId(e.id);
                    showModal();
                  }}
                  style={{
                    width: "100%",
                    background: "white",
                    borderRadius: "12px",
                    borderLeft: "1px solid #c2c2c2",
                    borderRight: "1px solid #c2c2c2",
                    borderTop: "1px solid #c2c2c2",
                    borderBottom: "1px solid #c2c2c2",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                >
                  <Flex
                    id="HEADER"
                    style={{
                      background: "#fafafa",
                      borderRadius: "12px 12px 0 0",
                      padding: "10px",
                      height: "40px",
                    }}
                    justify="space-between"
                    align="center"
                  >
                    <div>
                      {dayjs(e.header.createdAt).format("DD-MM-YYYY HH:mm")}
                    </div>
                    <div>
                      <Tag
                        color={
                          e.header.paymentsType === "OFFLINE" ? "gold" : "green"
                        }
                      >{`${e.header.paymentsLabel} ${(
                        e.header.price / 100
                      ).toFixed(2)} zł`}</Tag>
                    </div>
                  </Flex>
                  <div
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    {e.cart.map((row: any) => {
                      return (
                        <div
                          style={{
                            display: row.texts.length > 1 ? "flex" : "block",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            justifyContent:
                              row.texts.length > 1 ? "space-between" : "start",
                            ...row.style,
                          }}
                        >
                          {row.texts.map((text: any) => {
                            return (
                              <span style={{ ...text.style }}>{text.text}</span>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <hr
                    style={{
                      marginBottom: "0px",
                      marginTop: "20px",
                      width: "95%",
                      background: "#fafafa",
                      color: "#fafafa",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {e?.labels?.map((element: any) => (
                      <Tag
                        style={{
                          marginBottom: "5px",
                        }}
                        color={element.color}
                      >
                        {element.text}
                      </Tag>
                    ))}
                  </div>
                  <Flex
                    id="FOOTER"
                    style={{
                      borderRadius: "0 0 12px 12px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      minHeight: "40px",
                    }}
                    justify="space-between"
                    align="center"
                  >
                    <div>
                      <p style={{ marginBottom: 5 }}>
                        {e.footer.deliveryTimeType === "NOW"
                          ? "Najszybciej jak to mozliwe"
                          : `Z datą: ${e.footer.deliveryOrderDate}`}
                      </p>
                      {e.footer.deliveryType === "TO_THE_ADDRESS" && (
                        <a
                          href={`https://www.google.com/maps/place/?q=place_id:${e.footer.deliveryPlaceId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p style={{ margin: 0, fontWeight: 300 }}>
                            {e.footer.deliveryLabel}
                          </p>
                        </a>
                      )}
                    </div>
                    <div>
                      {e.footer.deliveryType === "TO_THE_ADDRESS" && (
                        <Tag icon={<SendOutlined />}>Dostawa</Tag>
                      )}
                      {e.footer.deliveryType === "SELF_PICKUP" && (
                        <Tag icon={<EnvironmentOutlined />}>
                          Odbiór osobisty
                        </Tag>
                      )}
                    </div>
                  </Flex>
                </div>
              );
            })}
        </div>
      </div>
      <Spin spinning={isLoading} fullscreen />
      <StyledModal
        title="Szczególy zamówienia"
        open={isModalOpen}
        width={800}
        onOk={handleOk}
        destroyOnClose
        onCancel={(e: any) => handleCancel(e)}
        closable={false}
        cancelText={"Zakończ"}
        okText={"Zamknij modal"}
        okButtonProps={{
          disabled: isLoading,
        }}
        cancelButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
          color: "danger",
          style: {
            display: status === "DONE" ? "none" : "",
          },
        }}
      >
        <OrderDetailsModal id={selectedOrderId} />
      </StyledModal>
    </>
  );
};
