import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  HolderOutlined,
  InfoCircleOutlined,
  MinusCircleTwoTone,
  MoreOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  Card,
  Button,
  Image,
  Select,
  Flex,
  Badge,
  TableColumnsType,
  Dropdown,
  Space,
  Switch,
  Form,
  FormInstance,
  Input,
  InputRef,
} from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import MealOptionsList from "./components/MealOptionsList";

export const CostumCard = styled(Card)`
  width: 100% !important;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  && .ant-card-body,
  .ant-card-head {
    padding: 8px !important;
  }
  && .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-cell {
    padding: 8px 8px 8px 4px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
  image: string;
  variants: number;
  status: string;
  id: number;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: "0.1%",
  },
  {
    title: "Image",
    dataIndex: "image",
    render: (_, { image }) => (
      <Image
        style={{ borderRadius: "7px" }}
        width={64}
        src={image}
        preview={false}
      />
    ),
    width: "0.1%",
  },

  {
    title: "Name",
    dataIndex: "name",
    width: "85%",
  },
  {
    title: "Variants",
    dataIndex: "variants",
    render: (_, { variants }) => (
      <p
        style={{
          textAlign: "end",
        }}
      >
        Warianty: {variants}
      </p>
    ),
    width: "100%",
  },
  {
    title: "Switch",
    dataIndex: "switch",
    render: (_, {}) => (
      <Switch defaultChecked onClick={(_e, event) => event.stopPropagation()} />
    ),
    width: "0.1%",
  },
  {
    title: "More",
    dataIndex: "id",
    render: (_, { id }) => <MoreOutlined key={id} />,
    width: "0.1%",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const MealsList: React.FC = () => {
  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      key: "1",
      image:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      name: "Parmoeńska",
      variants: 1,
      status: "AVAILABLE",
    },
    {
      id: 2,
      key: "2",
      image:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      name: "4 sery",
      variants: 2,
      status: "UNAVAILABLE",
    },
    {
      id: 3,
      key: "3",
      image:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      name: "Diabelska",
      variants: 4,
      status: "UNAVAILABLE",
    },
    {
      id: 4,
      key: "4",
      image:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      name: "Margarita",
      variants: 2,
      status: "AVAILABLE",
    },
    {
      id: 5,
      key: "5",
      image:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      name: "Peppereoni",
      variants: 13,
      status: "CURRENTLY_UNAVAILABLE",
    },
  ]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <CostumCard
      title="Pizza"
      extra={
        <Button icon={<PlusOutlined />} type="primary">
          Dodaj danie
        </Button>
      }
      style={{ width: 300 }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            showHeader={false}
            components={{
              body: {
                row: Row,
                cell: EditableCell,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: MealOptionsList,
              expandIcon: () => null,
            }}
          />
        </SortableContext>
      </DndContext>
    </CostumCard>
  );
};

export default MealsList;
