import React from "react";
import { Col as ANTCol, Flex, Tag } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";

export const Col = styled(ANTCol)`
  &:hover {
    background-color: lightgreen;
  }
`;

export const ModalTaskRow: React.FC<any> = ({
  rowData,
  setOpen,
  setReservationId,
}) => {
  return (
    <Col
      style={{
        width: "100%",
        padding: "10px",
        borderTop: "1px solid #ddd",
        borderLeft: "1px solid #ddd",
        borderRight: "1px solid #ddd",
        cursor: "pointer",
      }}
      onClick={() => {
        setReservationId(rowData.id);
        setOpen(true);
      }}
    >
      <Flex
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>{dayjs(rowData.startTime).format("HH:mm")}</div>
        <div>{rowData.details?.cart?.[0]?.cart_positoin?.meal_name}</div>
        <div>{rowData.details?.cart?.[0]?.cart_positoin?.meal_name}</div>
      </Flex>
    </Col>
  );
};
