import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { QueryClient } from "react-query";
import { notification } from "antd";
export const PATH = process.env.REACT_APP_API_URL ?? "http://localhost:8000";
// export const PATH = process.env.REACT_APP_API_URL ?? "https://s2.flexmenu.pl";

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const autoLogout = () => {
  Cookies.remove("accessToken");
  notification.error({
    message: "Token autoryzacyjny wygasł, zaloguj się ponownie",
  });
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const login = async (data: {
  username: any;
  password: any;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/common_api/login`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const logout = async (): Promise<any> => {
  return await axiosInstance.post<any>(`${PATH}/logout`);
};

export const isLoggedIn = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/common_api/is-logged-in`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getServicesCategories = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/admin/service_categories`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getCompanyBranches = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/common_api/admin/company_branches`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getServicesOrders = async (branchId: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/service_orders/${branchId}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};
export const getServicesCalendarOrders = async (
  branchId: any,
  startTime: string,
  endTime: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/service_orders_calendar/${branchId}/${startTime}/${endTime}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getBranchInfo = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/common_api/admin/company_branch/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getResourcesCategories = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/admin/resource_categories`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const createResourcesCategories = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/services_api/resource_categories`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getServiceCategory = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/admin/service_category/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getResourceCategory = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/admin/resource_category/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getServices = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/services/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getServiceOrder = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/service_order/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getResources = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/services_api/resources/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const createServicesCategories = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/services_api/service_categories`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const createServices = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/services_api/services`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const createResource = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/services_api/resources`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getDeliveryZones = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/common_api/delivery-zones/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const setDeliveryZones = async ({
  data,
}: {
  data: any;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/v1/common_api/delivery-zone`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const deleteDeliveryZone = async ({ id }: { id: any }): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      `${PATH}/v1/common_api/delivery-zone/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getProductOrders = async ({ id, status }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/products_api/product-orders/${id}/${status}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const updateProductOrders = async ({ id, body }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.patch<any>(
      `${PATH}/v1/products_api/product-orders/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getProductOrderDetails = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/v1/products_api/product-order-details/${id}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    },
  },
});
