import { Avatar, Flex, Modal, Space } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getServiceOrder } from "../../../service";
import styled from "styled-components";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
const Meal = styled.div``;

export const P = styled.p<{
  $size: string;
  $weight: string;
  $height: string;
  $family?: string;
}>`
  margin: 0;
  font-family: ${({ $family = "Lato" }) => $family}, sans-serif;
  font-size: ${({ $size }) => $size}px;
  font-weight: ${({ $weight }) => $weight};
  line-height: ${({ $height }) => $height}px;
`;

export const ServiceDetailsModal: React.FC<any> = ({
  setOpen,
  open,
  reservationId,
  isOrder,
}) => {
  const [content, setContent] = useState<any>([]);

  const hideModal = () => {
    setOpen(false);
  };

  useQuery<any, Error>(
    ["/service-order", reservationId],
    () => reservationId && getServiceOrder({ id: reservationId }),
    {
      onSuccess(data) {
        setContent(data);
      },
      enabled: !!open && !!reservationId,
    }
  );

  return (
    <Modal
      title={"Rezerwacja numer: " + reservationId}
      open={open}
      onOk={hideModal}
      onCancel={hideModal}
      okText="OK"
      cancelText="Cancel"
      zIndex={1100}
    >
      <Meal>
        <Space
          style={{
            marginBottom: "10px",
          }}
        >
          <P $size="14" $weight="600" $height="20">
            {"Dane rezerwujacego"}
          </P>
        </Space>

        <Flex
          vertical
          style={{
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              columnGap: "4px",
            }}
          >
            <span>
              <P $size="14" $height="16" $weight="400" $family="Lato">
                Imie i nazwisko:
              </P>
            </span>
            <span>
              <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                {content?.clinetName}
              </P>
            </span>
          </Space>
          <Space
            style={{
              columnGap: "4px",
            }}
          >
            <span>
              <P $size="14" $height="16" $weight="400" $family="Lato">
                Telefon:
              </P>
            </span>
            <span>
              <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                {content?.telNumber}
              </P>
            </span>
          </Space>
          <Space
            style={{
              columnGap: "4px",
            }}
          >
            <span>
              <P $size="14" $height="16" $weight="400" $family="Lato">
                E-mail:
              </P>
            </span>
            <span>
              <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                {content?.email}
              </P>
            </span>
          </Space>
        </Flex>
        <Space
          style={{
            marginBottom: "10px",
          }}
        >
          <P $size="14" $weight="600" $height="20">
            {isOrder ? "Zamówienie" : "Rezerwacja"}
          </P>
        </Space>

        <Flex
          vertical
          style={{
            marginBottom: "10px",
          }}
        >
          {content?.details?.cart?.map((control: any) => {
            return (
              <Space
                style={{
                  columnGap: "4px",
                }}
              >
                <span>
                  <P $size="14" $height="16" $weight="400" $family="Lato">
                    {control?.cart_positoin.meal_quantity}
                    {"x "}
                    {control?.cart_positoin.meal_name} -{" "}
                    {(
                      (control?.cart_positoin.meal_quantity *
                        control?.cart_positoin.meal_price) /
                      100
                    ).toFixed(2)}
                    {" zł"}
                  </P>
                </span>
                <span>
                  <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                    {control?.value}
                  </P>
                </span>
              </Space>
            );
          })}
        </Flex>
        <Space
          style={{
            marginBottom: "10px",
          }}
        >
          <P $size="14" $weight="600" $height="20">
            {"Termin"}
          </P>
        </Space>

        <Flex
          vertical
          style={{
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              columnGap: "4px",
            }}
          >
            <span>
              <P $size="14" $height="16" $weight="400" $family="Lato">
                {dayjs.utc(content.startTime).format('DD-MM-YYYY HH:mm')}
              </P>
            </span>
          </Space>

        </Flex>
      </Meal>
      {content?.details?.additionalSections.map(
        ({ sectionName, controls }: any) => {
          return (
            <Meal>
              <Space
                style={{
                  marginBottom: "10px",
                }}
              >
                <P $size="14" $weight="600" $height="20">
                  {sectionName}
                </P>
              </Space>

              <Flex
                vertical
                style={{
                  marginBottom: "10px",
                }}
              >
                {controls.map((control: any) => {
                  return (
                    <Space
                      style={{
                        columnGap: "4px",
                      }}
                    >
                      <span>
                        <P $size="14" $height="16" $weight="400" $family="Lato">
                          {control.name}:
                        </P>
                      </span>
                      <span>
                        <P
                          $size="14"
                          $height="20"
                          $weight="300"
                          $family="Lato-Light"
                        >
                          {control.value}
                        </P>
                      </span>
                    </Space>
                  );
                })}
              </Flex>
            </Meal>
          );
        }
      )}
      <Meal>
        <Space
          style={{
            marginBottom: "10px",
          }}
        >
          <P $size="14" $weight="600" $height="20">
            Płatność
          </P>
        </Space>

        <Flex
          vertical
          style={{
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              columnGap: "4px",
            }}
          >
            <span>{content?.details?.payments}</span>
          </Space>
        </Flex>
      </Meal>
    </Modal>
  );
};
